
import {
  IonContent,
  IonPage,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonButton,
  IonBackButton,
  IonInput,
  alertController,
  modalController,
  IonSpinner,
  IonIcon,
} from "@ionic/vue";
import { defineComponent, reactive, ref, onMounted } from "@vue/runtime-core";
import RequestCurious from "../../components/RequestCurious.vue";
import GMap from "../GMap.vue";
import { Geolocation } from "@capacitor/geolocation";
import { useStore } from "vuex";
import MakeRequest from "../../components/MakeRequest.vue";
import { search } from "ionicons/icons";

export default defineComponent({
  components: {
    IonContent,
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonInput,
    RequestCurious,
    GMap,
    IonSpinner,
    IonIcon,
  },
  setup() {
    const store = useStore();
    let geocoderService: any = null;
    const location = ref<string>("");

    const locResult = ref<any>();
    const state = reactive({
      items: [],
      isQuestion: false,
      search: "",
      position: {
        lat: 0,
        lng: 0,
      },
      contentLoading: true,
      flag: false,
    });

    const markers = ref<any>({});

    const onQuestion = async () => {
      state.isQuestion = !state.isQuestion;
    };

    const searchCleared = () => {
      state.search = "";
    };

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const filterItems = (searchTerm: any) => {
      return state.items.filter((item) => {
        // return item.title.toLowercase().indexOf(searchTerm.toLowerCase()) > -1;
        console.log(searchTerm);
        console.log(item);
      });
    };

    const addLocation = (location: any) => {
      markers.value = {
        lat: location.position.lat,
        lng: location.position.lng,
        title: location.address,
      };

      store.commit("markersLocation", {
        address: location.address,
        lat: location.position.lat,
        lng: location.position.lng,
      });
    };

    const findLocation = () => {
      geocoderService.geocode(
        { address: location.value, language: "ko" },
        (results: any, status: string) => {
          if (status != "OK") {
            presentAlert("다시 입력해주세요.");
          } else {
            locResult.value = {
              position: results[0].geometry.location.toJSON(),
              address: results[0].formatted_address,
            };

            addLocation(locResult.value);
          }
        }
      );
    };

    const handleMapDidLoad = (map: any, GServices: any) => {
      console.log("handleMapDidLoad");
      console.log("map", map);
      console.log("GServices", GServices);
      geocoderService = new GServices.Geocoder();
    };

    const onLocation = async () => {
      const getGeoLocation = store.getters["getGeoLocation"];
      if (getGeoLocation.lat && getGeoLocation.lng) {
        state.position.lat = getGeoLocation.lat;
        state.position.lng = getGeoLocation.lng;
      }

      if (state.position.lat == 0 && state.position.lng == 0) {
        Geolocation.getCurrentPosition({
          enableHighAccuracy: true,
          timeout: 1000,
        })
          .then((response) => {
            console.log(response);
            const latitude = response.coords.latitude;
            const longitude = response.coords.longitude;
            state.flag = true;
            store.commit("geolocation", {
              lat: latitude,
              lng: longitude,
            });
            state.position.lat = latitude;
            state.position.lng = longitude;
            console.log(state.position);
            state.contentLoading = false;
          })
          .catch((error) => {
            /**
             * 1초안에 위치정보를 불러올수 없을때, 서울을 기본 위치로 한다. ( 사용자가 위치를 검색하여 입력하는 화면이기 때문 )
             * 이때 vuex 에 위치정보는 기록하지 않는다.
             * 37.553656, 126.980927 ( 서울 남산 )
             */
            console.log(error);
            state.position.lat = 37.553656;
            state.position.lng = 126.980927;
            state.flag = true;
            state.contentLoading = false;
          });
      } else {
        state.flag = true;
        state.contentLoading = false;
      }
    };

    const onLinkMakeRequest = async () => {
      if (markers.value.title) {
        const modal = await modalController.create({
          component: MakeRequest,
        });
        return modal.present();
      } else {
        presentAlert("위치를 선택해주세요.");
      }
    };

    onMounted(() => {
      state.search = "";
      state.position = {
        lat: 0,
        lng: 0,
      };
      onLocation();
      markers.value = {};
    });

    return {
      state,
      searchCleared,
      onQuestion,
      markers,
      addLocation,
      handleMapDidLoad,
      findLocation,
      location,
      locResult,
      filterItems,
      onLinkMakeRequest,
      search,
    };
  },
});
