
import {
  alertController,
  IonPage,
  IonContent,
  IonSpinner,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  loadingController,
  IonInput,
  IonTextarea,
  modalController,
} from "@ionic/vue";
import { defineComponent, reactive, onMounted, watch } from "@vue/runtime-core";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";
import services from "../services";
import * as V from "vee-validate/dist/vee-validate";

export default defineComponent({
  components: {
    IonPage,
    IonContent,
    IonSpinner,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonInput,
    IonTextarea,
    VField: V.Field,
    VForm: V.Form,
    VErrorMessage: V.ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const state = reactive({
      contentLoading: false,
      form: {
        name: "",
        desc: "",
        file: "",
        address: "",
        lat: 0,
        lng: 0,
      },
      file: {
        preview: "",
        src: {
          name: "",
          size: 0,
        },
      },
      isRequest: false,
    });

    const presentAlert = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
        ],
      });
      await alert.present();
      const { role } = await alert.onDidDismiss();
      console.log("onDidDismiss resolved with role", role);
    };

    const onFileSelect = async (e: Event) => {
      const target = e.target as HTMLInputElement;
      const files = target.files;

      if (!files || !files[0]) {
        return;
      }

      let fileExt = files[0].name.substring(files[0].name.lastIndexOf(".") + 1);
      fileExt = fileExt.toLowerCase();
      if (
        ["jpeg", "png", "gif", "bmp", "jpg"].includes(fileExt) &&
        files[0].size <= 20971520 // 바이트
      ) {
        const reader = new FileReader();
        reader.onload = (e: Event) => {
          // base64
          const target = e.target as any;
          state.file.preview = target.result;
          state.file.src = files[0];
          console.log(state.file);
        };
        reader.readAsDataURL(files[0] as unknown as Blob);
      } else if (20971520 <= files[0].size) {
        presentAlert("20mb 이하만 등록 가능합니다.");
      } else {
        presentAlert("이미지를 다시 선택해 주세요.");
      }
      console.log(state.file);
    };

    const onFileRemove = async () => {
      state.file = {
        preview: "",
        src: {
          name: "",
          size: 0,
        },
      };
    };

    const onDismissModal = async (type: any) => {
      modalController.dismiss();
      if (type == "submit") {
        if (window.history.length > 1) {
          router.replace("/");
        }
      }
    };

    const onSubmit = async () => {
      const loading = await loadingController.create({
        cssClass: "my-custom-class",
        message: "등록 중입니다.",
      });
      await loading.present();

      const file = {
        fullPath: "",
        name: "",
        path: "",
      };

      if (state.file.preview != "") {
        if (state.file.src.name) {
          const form = new FormData();
          form.append("uploadFile", state.file.src as unknown as Blob); // api file name
          await services
            .fileAdd(form)
            .then((response) => {
              const { data } = response;
              file.fullPath = data.fullPath;
              file.name = data.name;
              file.path = data.path;
            })
            .catch((error) => {
              console.log(error);
            });
        }
      }

      const params = {
        name: state.form.name,
        content: state.form.desc,
        lat: state.form.lat,
        lng: state.form.lng,
        address: state.form.address,
      };

      if (file.name != "") {
        Object.assign(params, {
          picture: file.name,
        });
      }

      console.log(params);
      services
        .requestAdd(params)
        .then((response) => {
          console.log(response);

          // 마커정보 초기화
          store.commit("markersLocation", {
            address: "",
            lat: "",
            lng: "",
          });

          if (response.status == 200) {
            setTimeout(function () {
              loading.dismiss();
              state.isRequest = true;
              presentAlert("등록 되었습니다", async () => {
                onDismissModal("submit");
              });
            }, 1000);
          } else {
            setTimeout(function () {
              loading.dismiss();
            }, 1000);
          }
        })
        .catch((error) => {
          let message = "";
          try {
            message = error.response.data.message;
          } catch (e) {
            message = "";
            console.log(e);
          }
          setTimeout(function () {
            loading.dismiss();
            presentAlert(message);
          }, 1000);
        });
    };

    const isRequired = (value: any) => {
      if (!value) {
        return "이 영역을 입력해주세요.";
      }
      return true;
    };

    const presentConfirm = async (message: string, callback: any = null) => {
      const alert = await alertController.create({
        header: "알림",
        backdropDismiss: false,
        message: message,
        buttons: [
          {
            text: "확인",
            handler: () => {
              if (callback) {
                callback();
              }
            },
          },
          {
            text: "취소",
          },
        ],
      });
      await alert.present();
    };

    const fetch = () => {
      const getMarkersLocation = store.getters["getMarkersLocation"];
      console.log(getMarkersLocation);
      state.form.address = getMarkersLocation.address;
      state.form.lat = getMarkersLocation.lat;
      state.form.lng = getMarkersLocation.lng;
    };
    const onReset = async () => {
      presentConfirm("글 작성을 취소 하시겠습니까?", async () => {
        window.history.length > 1 ? router.go(-1) : router.push("/");
      });
    };

    watch(
      () => route.path,
      () => {
        if (!state.isRequest) {
          // 히스토리 모드로 뒤로가기
          onDismissModal("close");
        }
      }
    );

    onMounted(() => {
      fetch();
    });

    return {
      state,
      onFileSelect,
      onFileRemove,
      onSubmit,
      onReset,
      isRequired,
      onDismissModal,
    };
  },
});
