
import { ref, onMounted, watch, reactive } from "vue";
import { useStore } from "vuex";
export default {
  name: "GMap",
  props: {
    center: Object,
    zoom: Number,
    mapType: String,
    disableUI: Boolean,
    markers: Object,
    mapDidLoad: Function,
  },
  setup(props: any) {
    const store = useStore();
    const state = reactive({
      geolocation: {
        lat: 0,
        lng: 0,
      },
    });
    //  the google map object
    const map = ref();

    // the map element in the template
    const mapDivRef = ref(null);

    let currentMarkers: any = null;

    const icon = "/assets/icon/map_place.svg";

    const clearMarkers = () => {
      if (currentMarkers) {
        currentMarkers.setMap(null);
      }
    };

    // called whenever new markers are added
    const calcMapBounds = () => {
      const bounds = new (window as any).google.maps.LatLngBounds();
      bounds.extend(
        new (window as any).google.maps.LatLng(
          props.markers.lat,
          props.markers.lng
        )
      );
      map.value?.fitBounds(bounds);
      if (map.value.getZoom() > 14) map.value.setZoom(14);
    };

    const loadMapMarkers = () => {
      clearMarkers();
      // put marker on the map
      const mapMarker = new (window as any).google.maps.Marker({
        position: new (window as any).google.maps.LatLng(
          props.markers.lat,
          props.markers.lng
        ),
        map: map.value,
        title: props.markers.title,
        icon: {
          url: icon,
          scaledSize: { width: 30, height: 30 },
        },
      });

      // info window?
      mapMarker.infoWindow = new (window as any).google.maps.InfoWindow({
        content: props.markers.title,
      });

      mapMarker.addListener("click", () => {
        if (mapMarker.infoWindow != null) {
          mapMarker.infoWindow.open(map.value, mapMarker);
        }
      });
      currentMarkers = mapMarker;

      // make sure all of the markers are visible
      calcMapBounds();
    };

    const onLocation = async () => {
      const getGeoLocation = store.getters["getGeoLocation"];
      if (getGeoLocation.lat && getGeoLocation.lng) {
        state.geolocation.lat = getGeoLocation.lat;
        state.geolocation.lat = getGeoLocation.lng;
      }
    };

    const initMap = () => {
      map.value = new (window as any).google.maps.Map(mapDivRef.value, {
        mapTypeId: props.mapType || "roadmap",
        zoom: props.zoom || 14,
        disableDefaultUI: props.disableUI || false,
        center: props.center || {
          lat: state.geolocation.lat,
          lng: state.geolocation.lng,
        },
      });
      // loadMapMarkers();

      props.mapDidLoad && props.mapDidLoad(map, (window as any).google.maps);
    };

    // load in the google script
    onMounted(() => {
      const key = "AIzaSyC36GGvbUEiqK8IoZ3HheJsTphpGbvy8cU";
      // create the script element to load
      const googleMapScript = document.createElement("SCRIPT");
      googleMapScript.setAttribute(
        "src",
        `https://maps.googleapis.com/maps/api/js?key=${key}&language=ko`
      );
      googleMapScript.onload = function () {
        setTimeout(function () {
          initMap();
        }, 500);
      };
      googleMapScript.setAttribute("defer", "");
      googleMapScript.setAttribute("async", "");
      document.body.appendChild(googleMapScript);
    });

    watch(
      () => props.markers,
      () => {
        loadMapMarkers();
      }
    );

    (window as any).initMap = () => {
      // draggable markers
      // const directionsService = new (
      //   window as any
      // ).google.maps.DirectionsService();
      // const directionsRenderer = new (
      //   window as any
      // ).google.maps.DirectionsRenderer({
      //   draggable: true,
      //   map,
      // });
      // console.log(directionsService);
      // console.log(directionsRenderer);
    };

    return {
      mapDivRef,
      state,
      onLocation,
    };
  },
};
