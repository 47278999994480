<template>
  <div class="curious">
    <img src="@/assets/img/map_place.png" alt="스탬프" class="stamp_img_02" />
    <div class="guide_01">
      <p class="curious_guide_text">나만 알기엔 아까운 명소가 있다면</p>
      <p class="curious_guide_text">스탬프 팝에게 알려주세요!</p>
    </div>
    <div class="guide_02">
      <p class="curious_guide_text">주소를 검색해</p>
      <p class="curious_guide_text">위치를 선택한 후</p>
      <p class="curious_guide_text">요청하기를 눌러주세요.</p>
      <p class="curious_guide_text guide_bar">
        <img src="@/assets/img/arrow.png" alt="터치" class="arrow_img" />
      </p>
    </div>
    <ul class="fs_link_box z_box">
      <li>
        <button class="fs_link">요청하기</button>
      </li>
    </ul>
  </div>
</template>

<style lang="scss" scoped>
.stamp_img_02 {
  top: 30%;
  transform: translate(-50%, -30%);
}
.guide_01 {
  width: 66%;
}
.guide_02 {
  .curious_guide_text {
    font-size: 0.93rem;
    font-weight: normal;
  }
}
.fs_link {
  background-color: var(--ion-color-bg-reddishPink);
  color: var(--ion-color-whiteFour);
  border: 0.13rem solid var(--ion-color-bg-whiteFour);
}
</style>
